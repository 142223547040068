#blogPageHeading {
    color: var(--textColour);
    text-align: center;
}
#blogWrapper {
    width: 60%;
    margin: 0 auto;
    min-height: 90vh;
}
.blogSnippet {
    background-color: var(--colour7);
    color: var(--colour1);
    padding: 2%;
    margin-bottom: 1%;
}
.blogSnippetBody {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 1%;
}
.blogPostHeading {
    color: var(--colour5)
}
.blogCreator {

}
.postMetaDataWrapper {
    display: flex;
    justify-content: end;
}
.postMetaData {
    display: flex;
    white-space: nowrap;
    padding: 1%;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), var(--colour5);
}
.postMetaDataTopLayer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.profilePhoto {
    width: 75px;
    height: 75px;
    margin: 3%;
    border-radius: 50px;
}
.deleteButton {
    background-color: red;
    border: none;
}