#altLandingPage {
    width: 80%;
    margin: 0 auto;
}

#headerImageWrapper {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 40vw;
    display: flex;
    justify-content: center;
}

#headerCTA {
    width: 100%;
    background-color: var(--colour3);
    height: 40vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#headerImage {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 40vw;
    display: inline-block;
}

#RoleButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2%;
    
}

#IAmA {
    font-size: xx-large;
    text-align: center;
    color: var(--textColour);
}

#selectOne {
    color: var(--colour6);
    font-size:medium;
    display: block;
    text-align: center;
}

.roleButton {
    height: 70px;
    width: 13vw;
    font-size: 2vw;
    border-radius: 0;
    border: none;
    margin: 0 auto;
    margin-bottom: 5%;
    margin-left: 1%;

}

#tagLineWrapper {
    margin-top: 20px;
    width: 100%;
    background-color: var(--colour2);
    margin: 0 auto;
    margin-bottom: 2%;
}

#tagLine {
    color: var(--textColour);
    font-size: xx-large;
    text-align: center;
    padding: 3%;
}

.salePointWrapper {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}

.salePointWrapperDiv {
    background-color: var(--colour3);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 25%;
    margin-bottom: 2%;
}

.salePoint {
    color: var(--textColour);
    width: 25vw;
    font-size: xx-large;
    margin-bottom: 0;
}

.salePointLinkLine {
    height: 1px;
    width: 30%;
    background-color: white;
}

.salePointDesc {
    color: var(--textColour);
    font-size: x-large;
    width: 25%;
    text-align: center;
}

#CTAHeading {
    text-align: center;
    color: var(--textColour);
    background-color: var(--colour2);
    font-size: 5vw;
    margin-bottom: 0;
}

#CTAWrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;
}

.CTA {
    width: 50%;
    text-align: center;
}

.CTAButton:hover {
    animation: changeColour 0.1s linear forwards;
}

.CTAButton {
    font-size: 3vw;
    text-decoration: none;
    height: 100%;
    color: white;
    padding: 1%;
    background-color: var(--colour5);
    border-radius: 5px;
}

a {
    color: var(--textColour);
}

#CTAImage {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 130%; 
    height: auto; 
}

#CTAImageWrapper {
    margin: 0 auto;
    margin-top: 0;
    width: 100%; 
    overflow: hidden; 
    display: inline-block;
    position: relative;
    height: 40vw; 
    overflow: hidden;
}
.StepsBorder {
    width: 1px;
    background-Color: white; 
    display: inline-block;
}
#StepsWrapper {
    display: flex;
    justify-content: space-evenly;
    margin: 2% 0 2% 0;
}

.StepImages {
    width: 15vw;
}

.Step {
    width: 30vw;
    text-align: center;
}

.StepHeading {
    color: var(--textColour);
    font-size: xxx-large;
}

.StepValue {
    color: var(--textColour);
    width: 80%;
    margin: 0 auto;
    margin-left: 1%;
    display: inline-block;
}

#landingPageFinePrint {
    color: var(--textColour);
    text-align: center;
    margin-bottom: 0;
}

@media only screen and (max-width: 1210px) {
    #altLandingPage {
        width: 100%;
        margin: 0 auto;
    }

    #headerImageWrapper {
        margin: 0 auto;
        padding: 0;
        display: block;
        height: 100%;

    }

    #headerCTA {
        width: 100%;
        background-color: var(--colour3);
        height: 100%;

    }

    #IAmA {
        margin-bottom: 0;
    }

    #selectOne {
        color: var(--textColour);
        font-size: x-small;
        display: block;
        text-align: center;
    }
    .StepValue {
        font-size: medium;
    }
    #RoleButtons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    #headerImage {
        margin: 0 auto;
        padding: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: inline-block;
    }
    #CTAImage {
        width: 100%;
    }
    
}

@media only screen and (max-width: 770px) {
    #tagLine {
        font-size: x-large;
    }
    .salePointWrapperDiv {
        height: 75px;
    }
    .salePoint {
        font-size: larger;
    }
    .salePointDesc {
        font-size: 3vw;
    }
    .roleButton {
        width: 20vw;
        font-size: 3vw;
    }
    #StepsWrapper {
        display: block;
    }
    .Step {
        width: 100vw;
    }
    .StepImages {
        width: 30vw;
    }
    .StepHeading {
        font-size: 5vw;
    }
    .StepValue {
        font-size: 3vw;
        margin-bottom: 5%;
    }
    #landingPageFinePrint {
        font-size: 2vw;
    }
    #CTAImage {
        width: 100%;
        height: auto;
    }
    .StepsBorder {
        display: none;
    }
}

@keyframes changeColour {
    from {background-color: var(--colour5);}
    to {background-color: var(--colour3);}
}