#heading {
    color: white;
    text-align: center;
}
#blogCreateWrapper {
    width: 50%;
    margin: 0 auto;
    color: var(--textColour);
    min-height: 70vh;
}
#headingLabel {
    font-size: larger;
    color: white;
}
#editor {
    color: var(--colour1)
}
#postLabel {
    font-size: larger;
    color: white;
}
#postInput {
    margin-bottom: 5%;
}
#submitButton {
    float: right;
}
#blogOutput {
    color: var(--textColour)
}