@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
#blogPostWrapper {
    color: var(--textColour);
    width: 35%;
    margin: 0 auto;
    font-size: 16px;
    font-family: 'Inter', sans-serif;font-family: 'Inter', sans-serif;
}
.postMetaDataWrapper {
    display: flex;
    justify-content: end;
    width: 100%;
}
.postMetaData {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 1% 4% 1% 1%;
    width: fit-content;
    white-space: nowrap;
    background-color: var(--colour5);
}
.postMetaDataTopLayer {
    margin-left: 5%;

}
.postMetaDataTopLayer > p {
    margin-bottom: 0;
}
.profilePhoto {
    width: 75px;
    height: 75px;
    margin-left: 3%;
    border-radius: 50px;
}
.likeButton {
    cursor: pointer;
}